import tools from '../../../../libs/tools';
import { required } from '@vuelidate/validators';

const model = {
    id: "",
    slug: "",
    translations: {}
};
const translation = {
    category_id: "",
    locale: "",
    claim: "",
    description: "",
    image: "",
    link_slug: "",
    name: "",
    image_dark: "",
    publish_state: "",
    seo_title: "",
    seo_description: "",
    description_magazine: ""
};



export default {
    ...tools.models.apiCommons(model, translation, "category_id"),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            }
        }
    },
    translationValidation: {
        model: {
            link_slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            name: { required },
            publish_state: { required }
        }
    },
    uploads: [
        { el: "uppyImage", target: "image", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
        { el: "uppyImageDark", target: "image_dark", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]}
    ]
};
