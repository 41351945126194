<template>
    <div v-if='model && !model.is_null'>

        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid'>
                <div class='field col-12'>
                    <Toolbar class='mt-2'>
                        <template v-slot:start>
                            <div>
                                <span class="publish_state">Stato di pubblicazione </span>
                                <Dropdown
                                    v-model="v$.model.publish_state.$model"
                                    :options="publish_states"
                                    optionLabel="label"
                                    optionValue="code"
                                    placeholder="Stato di pubblicazione" />
                            </div>
                        </template>
                    </Toolbar>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12 md:col-8'>
                    <div class="p-inputgroup">
                    <span class='p-float-label'>
                        <InputText id='t_slug'
                                   type='text'
                                   v-model="v$.model.link_slug.$model"
                                   :class="{'p-invalid': v$.model.link_slug.$invalid && v$.model.link_slug.$dirty}"
                        />
                        <Button label='Fix' class="p-button-warning" title='Correggi SLUG' @click='model.link_slug = sluggify(model.link_slug)'/>
                        <Button icon="pi pi-angle-double-down" class="p-button-primary" title='Eredita' @click='model.link_slug = row.slug'/>
                        <Button icon="pi pi-sort-up" class="p-button-info" title='Dal titolo' @click='model.link_slug = sluggify(model.title)'/>
                        <label for='t_slug'>Slug</label>
                    </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='name'
                                   type='text'
                                   v-model="v$.model.name.$model"
                                   :class="{'p-invalid': v$.model.name.$errors.length}"
                        />
                        <label for='name'>Nome categoria</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='claim'
                                   type='text'
                                   v-model="model.claim"
                        />
                        <label for='claim'>Claim</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='model.description'
                        :key='"cont_cat_desc_"+id+"_"+lang'
                        toolbar='essential'
                        contentType='html'/>
                    <label>Descrizione</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='model.description_magazine'
                        :key='"cont_cat_desc_"+id+"_"+lang'
                        :toolbar="toolbar"
                        contentType='html'/>
                    <label>Descrizione per magazine</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-6'>
                    <uppy-dashboard
                        :uppy='uppyImage'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='image'
                                   type='text'
                                   v-model="model.image"
                        />
                        <label for='image'>Immagine</label>
                    </span>
                    <div v-if='model.image' class='text-center'>
                        <img :src='model.image' alt='Model Image' :height='uppyDashboardProps.height-30'/>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-6'>
                    <uppy-dashboard
                        :uppy='uppyImageDark'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='image_dark'
                                   type='text'
                                   v-model="model.image_dark"
                        />
                        <label for='image_dark'>Immagine dark theme</label>
                    </span>
                    <div v-if='model.image_dark' class='text-center'>
                        <img :src='model.image_dark' alt='Model Image' :height='uppyDashboardProps.height-30'/>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='seo_title'
                                   type='text'
                                   v-model="model.seo_title"
                        />
                        <label for='seo_title'>SEO Title</label>
                    </span>
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='seo_description'
                                   type='text'
                                   v-model="model.seo_description"
                        />
                        <label for='seo_title'>SEO Description</label>
                    </span>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Navigations from '../../../../mixins/navigations';
import Notifications from '../../../../mixins/notifications';
import Uploader from '../../../../mixins/uploader';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/Categories');
const commonStore = createNamespacedHelpers('Common');

import useVuelidate from '@vuelidate/core'

import tools from '../../../../libs/tools';
import model from './model';

export default {
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.translationValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
        ...commonStore.mapGetters(['languages', 'publish_states']),
        lastUpdateDate() {
            return tools.time.lastUpdate(this.model['last_update']);
        },
    },
    watch: {
        row(n){
            this.initModel(n);
        },
        lang(){
            this.initModel(this.row);
        },
        dirty(n){
            if (n) this.formIsDirty();
            else this.formIsClean();
        }
    },
    mounted() {
        this.initModel(this.row);
        this.prepareUploads(model.uploads, this.lang);
    },
    methods: {
        ...mapMutations(['formIsDirty', 'formIsClean']),
        ...mapActions(['saveTranslation']),
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }


            this.saveTranslation({
                data: this.model,
                parent_id: model.translationParentId(this.model)
            }).then(() => {
                this.formIsClean();
                this.ShowSuccess('Ottimo', 'Traduzione salvata con successo');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initModel(mod){
            this.dirty = false;
            const base = model.newTranslation(this.id, this.lang);
            if (mod && mod.translations && mod.translations[this.lang]){
                this.model = tools.models.fillTranslation(mod.translations[this.lang], base);
                return;
            }

            this.model = base;
        }
    },
    props: {
        lang: {
            type: String,
            default: 'it'
        },
        id: {
            type: String
        }
    },
    mixins: [
        Navigations, Notifications, Uploader
    ],
    data(){
        return {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'header': [2, 3, 4, 5, 6, false] }],
                [{ 'align': [] }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['link'],
            ]
        }
    }
}
</script>
